<script setup lang="ts">

import { Link } from '@inertiajs/vue3'


const props = defineProps<{
    article: {
        id: string
        title: string
        url: string
        thumbnail: string
        snippet: string
        isForLoggedIn: false
        isShowFull: true
    }
}>()

</script>

<template>
    <div class="col-lg-4 col-sm-6 mb-4 hover-animate">
        <div class="card shadow border-0 h-100">
            <Link
                :href="props.article.url"
                :title="props.article.title"
            >
                <img
                    :src="props.article.thumbnail"
                    :title="props.article.title"
                    :alt="props.article.title"
                    class="img-fluid card-img-top"
                >
            </Link>

            <div v-if="props.article.isForLoggedIn" class="position-absolute top-0 left-0 z-index-10 p-3">
                <div class="pre-title bg-primary py-1 px-2 cl-light rounded-sm mb-3">
                    Pluz
                </div>
            </div>

            <div class="card-body d-flex flex-column">
                <h4 class="mt-2 mb-3">
                    <Link
                        :href="props.article.url"
                        :title="props.article.title"
                        class="text-dark"
                    >
                        {{ props.article.title }}
                    </Link>
                </h4>

                <p class="mb-3 mb-sm-4">
                    {{ props.article.snippet }}
                </p>

                <p v-if="!props.article.isShowFull" class="mb-3 mb-sm-4">
                    You need to login!
                </p>

                <Link
                    :href="props.article.url"
                    :title="props.article.title"
                    class="btn bg-light mr-auto mb-2 tt-n font-weight-600 mt-auto"
                >
                    Read more
                </Link>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>